














































































import { Component, Prop, Vue } from 'vue-property-decorator'
import  AddUnstakeLongDeposit from '@/components/AddUnstakeLongDeposit.vue'
import LongDepositAccountLine from '@/components/LongDepositAccountLine.vue'
import { DepositRange, getAccount, getAPIConfiguration, LongDeposit } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Conf } from '@/models/Conf'
import { formatDate } from '@/utils'
import { Currency, TokenType } from '@/types'

@Component({
	components: {
		AddUnstakeLongDeposit,
		LongDepositAccountLine,
	},
})
export default class LongDepositAccount extends Vue {

	@Prop() depositAccount!: LongDeposit
	private account: Account | null = null
	private conf: Conf | null = null
	private headers = [
		{
			align: 'left',
			sortable: false,
			value: 'startAt',
			text: 'Deposit date',
			width: '20%',
		},
		{
			align: 'left',
			sortable: false,
			value: 'endAt',
			text: 'End date',
			width: '20%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'initialCapital',
			text: 'Initial capital',
			width: '20%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'currentCapital',
			text: 'Current capital',
			width: '20%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'earnings',
			text: 'Earnings',
			width: '20%',
		}
	]
	private loading = false
	private showLineInformation = false
	private showUnstakeDialog = false
	private selectedAccount: LongDeposit | null = null
	private selectedDepositLine: DepositRange[] | null = null

	private clickOnRow(item: any) {
		this.selectedDepositLine = item.ranges
		this.showLineInformation = true
	}
	private async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.account = (await getAccount(this.$store.state.jwt, this.depositAccount.currency as Currency, this.depositAccount.type as TokenType))[0]
		this.loading = false
	}

	private unstakeVisible (item: any): boolean {
		return item.getCurrentCapital().gt(0)
	}

	private unstakeEnabled (item: Account): boolean {
		if (!this.conf?.internalTransferable) {
			return false
		}
		for (const o of this.conf.internalTransferable) {
			if (o.transactionType === 'UNSTAKE') {
				return o.allowedCurrencies ? o.allowedCurrencies?.indexOf(item.currency) >= 0 : false
			}
		}
		return false
	}

	private onUnstake (item: any): void {
		this.selectedAccount = item
		this.showUnstakeDialog = true
	}

	async onSuccessUnstake (): Promise<void> {
		await this.$emit('updated')
		this.showUnstakeDialog = false
	}

	private formatDate (date: Date): string {
		return formatDate(date, this.$i18n)
	}

}
