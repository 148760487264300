var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(_vm._s(_vm.$t('longDeposit.details.title')))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),(_vm.localDepositRanges)?_c('v-data-table',{attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.localDepositRanges,"items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.capital",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.capital)+" ")]}},{key:"item.startAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startAt))+" ")]}},{key:"item.endAt",fn:function(ref){
var item = ref.item;
return [(item.endAt)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.endAt)))]):_c('span',[_vm._v("Ongoing")])]}},{key:"item.earnings",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.earnings)+" ")]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }