


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { getAPIConfiguration, getLongDepositInfo, LongDeposit } from '@/clients/cpinblocks'
import { Conf } from '@/models/Conf'
import Account from '@/components/Account.vue'
import LongDepositAccount from '@/components/LongDepositAccount.vue'

@Component({
	components: {
		Account,
		LongDepositAccount,
	},
})
export default class LongDepositView extends Vue {
	@Prop() currency!: string

	private conf: Conf | null = null
	private id = 0
	private loading = true
	private longDeposit: LongDeposit | null = null

	private async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.longDeposit = await getLongDepositInfo(this.$store.state.jwt, this.currency.toUpperCase())
		this.loading = false
	}

	private async refresh (): Promise<void> {
		this.longDeposit = await getLongDepositInfo(this.$store.state.jwt, this.currency.toUpperCase())
		this.id++
	}
}
