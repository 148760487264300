






































import { Component, Prop, Vue } from 'vue-property-decorator'
import  AddUnstakeLongDeposit from '@/components/AddUnstakeLongDeposit.vue'
import { DepositRange } from '@/clients/cpinblocks'
import { Conf } from '@/models/Conf'
import { BigNumber } from 'bignumber.js'
import { formatDate } from '@/utils'

@Component({
	components: {
		AddUnstakeLongDeposit,
	},
})
export default class LongDepositAccount extends Vue {
	@Prop() depositRanges!: DepositRange[]
	@Prop() conf!: Conf

	private headers = [
		{
			align: 'left',
			sortable: false,
			value: 'startAt',
			text: 'Deposit date',
			width: '20%',
		},
		{
			align: 'left',
			sortable: false,
			value: 'endAt',
			text: 'End date',
			width: '20%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'capital',
			text: 'Capital over period',
			width: '20%',
		},
		{
			align: 'right',
			sortable: false,
			value: 'earnings',
			text: 'Earnings over period',
			width: '20%',
		},
	]

	private get localDepositRanges (): DepositRange[] {
		const result: DepositRange[] = []
		for (let i = 0; i < this.depositRanges.length; i++) {
			let res = Object.assign({}, this.depositRanges[i])
			if (i === this.depositRanges.length - 1) {
				// last item
				res.endAt = undefined
			}
			if (!new BigNumber(res.capital).eq(0) ) {
				result.push(res)
			}
		}
		return result
	}

	private formatDate (date: Date): string {
		return formatDate(date, this.$i18n)
	}

}
